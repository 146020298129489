import { Component, ElementRef, OnInit, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
interface Tab {
  id: number;
  name: string;
  iconBlue: string;
  iconGreen: string;
}
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  @ViewChildren('el0, el1, el2, el3, el4, el5, el6, el7, el8, el9, el10, el11') elms: QueryList<any>;
  @ViewChild('header') header: ElementRef;
  public listTab: Tab[] = [
    { id: 0, name: 'Privacy Policy', iconBlue: '/assets/images/privacy-blue.svg', iconGreen: '/assets/images/privacy-green.svg' },
    { id: 1, name: 'Terms of Service', iconBlue: '/assets/images/terms-blue.svg', iconGreen: '/assets/images/terms-green.svg' },
    { id: 2, name: 'Cookie Policy', iconBlue: '/assets/images/cookie-blue.svg', iconGreen: '/assets/images/cookie-green.svg' },
    { id: 3, name: 'Acceptable Use Policy', iconBlue: '/assets/images/aup-blue.svg', iconGreen: '/assets/images/aup-green.svg' }
  ];
  public privatePolicy: number[];
  public termsofService: number[];
  public cookiePolicy: number[];
  public acceptableUsePolicy: number[];
  public selectedTab: number;

  public isBtnSelected = false;
  public mobileSelectedTab: Tab;

  public scrolledSelection = 0;

  detectedElms = [];

  constructor(
    private renderer: Renderer2
  ) {
    this.renderer.listen('window', 'scroll', this.detectElms.bind(this));
  }

  ngOnInit() {
    this.selectedTab = 0;
    this.mobileSelectedTab = this.listTab[this.selectedTab];
    this.privatePolicy = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    this.termsofService = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
    this.cookiePolicy = [1, 2, 3];
    this.acceptableUsePolicy = [1, 2, 3, 4, 5];
  }

  goTo(index) {
    const ele = document.getElementById(index);
    window.scrollTo(0, ele.offsetTop - 130);
  }

  detectElms() {
    const detectedElms = [];
    this.elms.forEach((elm, index) => {
      if (isInViewport(elm.nativeElement)) {
        this.scrolledSelection = elm.nativeElement.id;
        detectedElms.push(elm.nativeElement.id);
      }
    });
    this.detectedElms = detectedElms;
  }

  clickMenu() {
    this.isBtnSelected = !this.isBtnSelected;
  }


  isScrolledOver(i: number) {
    return i <= this.scrolledSelection;
  }

  selectTab(i: any) {
    this.selectedTab = i;
    this.mobileSelectedTab = this.listTab[i];
    this.isBtnSelected = false;
    window.scroll(0, 0);
    this.scrolledSelection = 0;
  }


}
function isInViewport(elm) {
  const elementTop = elm.offsetTop;
  const elementBottom = elementTop + elm.offsetHeight;
  const viewportTop = document.documentElement.scrollTop;
  const viewportBottom = viewportTop + document.documentElement.clientHeight;
  return elementBottom > viewportTop && elementTop < viewportBottom;
}
