import { Component } from '@angular/core';

declare var loader;
@Component({
  selector: 'app-start',
  templateUrl: './main.html'
})

export class MainComponent {


  constructor() { }

  changeOfRoutes() {
  }
}
